import { useEffect, useState } from "react";
import { getCertificateIDDetails } from "../api";
import { toast } from "react-toastify";

const useVerifyWithNumber = () => {
  const [certificateData, setCertificateData] = useState(null);
  const [certificateId, setCertificateId] = useState("");
  const [error, setError] = useState(null);
  useEffect(() => {
    const queryString = window?.location?.search;
    const params = new URLSearchParams(queryString);
    const certificateNumber = params.get("certificate_number");
    setCertificateId(certificateNumber ?? "");
    if (certificateNumber) {
      let body = {
        certificate_number: certificateNumber,
      };
      getCertificateIDDetails(body)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            setCertificateData(response?.data?.data);
            setError(null);
          } else if (response?.status_code === 422) {
            setError("Invalid Certificate");
          } else {
            setError("Something went wrong");
          }
        })
        .catch(() => {
          setError("Something went wrong");
        });
    }
  }, []);

  const handleChange = (e) => {
    setCertificateId(e.target.value);
  };

  const handleSubmit = () => {
    if (!certificateId) {
      toast.error("Invalid certificate");
    } else {
      let body = {
        certificate_number: certificateId,
      };
      const url = new URL(window.location.href);
      url.searchParams.set("certificate_number", certificateId);
      window.history.pushState({}, "", url.toString());
      getCertificateIDDetails(body)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            setCertificateData(response?.data?.data);
            setError(null);
          } else if (response?.status_code === 422) {
            setError("Invalid Certificate");
          } else {
            setError("Something went wrong");
          }
        })
        .catch(() => {
          setError("Something went wrong");
        });
    }
  };

  return {
    error,
    certificateId,
    handleSubmit,
    handleChange,
    certificateData,
  };
};

export default useVerifyWithNumber;
