import React from "react";
import VisitingCard from "../VisitingCard";
import Bigcard from "../BigCard";
import PapperCard from "../PapperCard";
import FoldableCard from "../FoldableCard";
import useVerifyWithNumber from "./useVerifyWithNumber";

const VerifyWithNumber = () => {
  const { error, certificateData, handleSubmit, handleChange, certificateId } =
    useVerifyWithNumber();

  return (
    <div>
      {/* commented as per client request */}
      <div className="row gx-2 pro-pt-6 pro-px-6 pro-justify-center  ">
        <div className="col-md-4 pro-d-flex pro-gap-3">
          <input
            className="pro-input"
            onChange={handleChange}
            value={certificateId}
            placeholder={"Enter certificate Number"}
            disabled
          />
          {/* <button
            className=" pro-btn pro-btn-primary"
            type="button"
            onClick={handleSubmit}
          >
            {" "}
            Submit{" "}
          </button> */}
        </div>
      </div>
      {!!error ? (
        <div className=" pro-d-flex pro-justify-center pro-w-100 pro-pt-5 ">
          <h5 className="error-custom">{error}</h5>
        </div>
      ) : (
        <div className="pro-mt-5">
          <div>
            {certificateData?.certificate_type === 1 && (
              <VisitingCard data={certificateData} />
            )}
            {certificateData?.certificate_type === 2 && (
              <Bigcard data={certificateData} />
            )}
            {certificateData?.certificate_type === 3 && (
              <PapperCard data={certificateData} />
            )}
            {certificateData?.certificate_type === 4 && (
              <FoldableCard data={certificateData} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifyWithNumber;
