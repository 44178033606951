import axios from "axios";

export const getCertificateDetails = async (id) => {
  const apiUrls = {
    WEBC: process.env.REACT_APP_CERTIFICATEURL,
    UAT: process.env.REACT_APP_UAT_CERTIFICATEURL,
    LIVE: process.env.REACT_APP_LIVE_CERTIFICATEURL,
  };

  const baseUri = apiUrls[process.env.REACT_APP_TYPE] || "invalid base uri";

  try {
    const res = await axios.get(`${baseUri}/qr/card-details?id=${id}`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getCertificateIDDetails = async (body) => {
  const apiUrls = {
    WEBC: process.env.REACT_APP_CERTIFICATEURL,
    UAT: process.env.REACT_APP_UAT_CERTIFICATEURL,
    LIVE: process.env.REACT_APP_LIVE_CERTIFICATEURL,
  };

  const baseUri = apiUrls[process.env.REACT_APP_TYPE] || "invalid base uri";

  try {
    const res = await axios.post(`${baseUri}/qr/search-card`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
